/**
 * Product list
 * -------------------------
 */

.ce-product-list {
    .headline {
        margin-bottom: rem-calc(20);
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: rem-calc(20);
    }

    &__item {
        display: flex;
        flex-direction: column;

        &-image {
            margin-bottom: rem-calc(10);
            text-align: center;

            img {
                max-width: 100%;
                aspect-ratio: 1/1;
                object-fit: contain;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
        }

        &-title {
            font-size: rem-calc(17);
            line-height: rem-calc(26);
            margin-bottom: rem-calc(5);
        }

        &-price {
            margin-bottom: rem-calc(10);

            .price {
                display: block;
                font-size: rem-calc(17);
                line-height: rem-calc(26);
                font-weight: bold;
                margin-bottom: rem-calc(5);

                &-vat {
                    display: block;
                    font-size: rem-calc(14);
                    line-height: rem-calc(17);
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        &__items {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include media-breakpoint-up(md) {
        .headline {
            margin-bottom: rem-calc(40);
        }

        &__items {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include media-breakpoint-up(lg) {
        &__items {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @include media-breakpoint-up(xl) {
        &__items {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}
