/**
 * Story-Teaser
 * -------------------------
 */

.ce-story-teaser {
    display: block;
    flex: 0 0 auto;

    &__image {
        background: no-repeat center;
        background-size: cover;
        margin-bottom: rem-calc(15);
        padding-top: percentage(2/3);

        &--desktop {
            display: none;
        }
    }

    &__content {
        padding-right: rem-calc(40);
        position: relative;
    }

    &__text {
        @extend %tiny;
        color: $body-color;
        margin-top: rem-calc(5);
    }

    &__read-more {
        position: absolute;
        right: 0;

        svg {
            @include animate(transform);
            fill: currentColor;
        }

        @at-root .ce-story-teaser--md-headline & {
            top: rem-calc(-5);
        }

        @at-root .ce-story-teaser--sm-headline & {
            top: rem-calc(-8);
        }
    }

    &:hover,
    &:focus {
        .ce-story-teaser__read-more svg {
            transform: translateX(rem-calc(10));
        }
    }

    @include media-breakpoint-up(md) {
        &__image {
            margin-bottom: rem-calc(20);

            &--landscape {
                padding-top: percentage(1/2);
            }

            &--desktop {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }

        &__read-more {
            @at-root .ce-story-teaser--md-headline & {
                top: rem-calc(-2);
            }

            @at-root .ce-story-teaser--sm-headline & {
                top: rem-calc(-5);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__image {
            &--landscape {
                padding-top: percentage(2/3);
            }

            &--portrait {
                padding-top: percentage(10/9);
            }
        }

        &__read-more {
            @at-root .ce-story-teaser--md-headline & {
                top: rem-calc(4);
            }
        }
    }
}
