/**
 * Accordion
 * -------------------------
 */

.ce-accordion {
    &__content {
        margin-bottom: rem-calc(20);
    }

    @include media-breakpoint-up(sm) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(780);
    }
}
