/**
 * Quote
 * -------------------------
 */

.ce-quote {
    &--blue {
        background: $blue;
        color: map-get($font-colors, 'light');
    }

    &--gray {
        background: $light-gray;
        color: map-get($font-colors, 'dark');
    }

    &__overline {
        @extend %tiny;
        margin: 0 0 rem-calc(10);
    }

    &__quote {
        @extend %headline-md;
    }

    &__text {
        padding: rem-calc(30) rem-calc(20);
    }

    &--without-image {
        .ce-quote__text {
            text-align: center;
        }
    }

    &--with-image {
        .ce-quote__wrapper {
            position: relative;
        }

        .ce-quote__image {
            img {
                @include img-fluid();
                display: block;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &--with-image {
            .ce-quote__text {
                padding-bottom: rem-calc(10);
            }

            .ce-quote__image {
                margin-left: auto;
                padding: 0 rem-calc(20)  rem-calc(20);
                max-width: rem-calc(120);
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__text {
            padding-bottom: rem-calc(60);
            padding-top: rem-calc(60);
        }

        &--without-image {
            .ce-quote__text {
                margin: 0 auto;
                max-width: 66%;
            }
        }

        &--with-image {
            .ce-quote__wrapper {
                @include make-row();
                align-items: center;
            }

            .ce-quote__text {
                @include make-col-ready();
                @include make-col(7);
                @include make-col-offset(1);
            }

            .ce-quote__image {
                @include make-col-ready();
                @include make-col(3);
                padding-bottom: rem-calc(40);
                padding-top: rem-calc(40);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__text {
            padding-bottom: rem-calc(100);
            padding-top: rem-calc(100);
        }

        &--with-image {
            .ce-quote__image {
                padding-bottom: rem-calc(50);
                padding-top: rem-calc(50);
            }
        }
    }
}
