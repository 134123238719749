/**
 * Floating action button
 * -------------------------
 */

.ce-floating-action-button {
    align-items: flex-end;
    bottom: rem-calc(10);
    display: flex;
    flex-direction: column;
    right: rem-calc(10);
    position: fixed;
    z-index: 1000;

    &__toggle {
        background: $blue;
        border: none;
        box-shadow: $box-shadow-overlay;
        color: $white;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        min-width: rem-calc(124);
        max-width: rem-calc(220);
        text-align: left;
        text-transform: uppercase;
        padding: rem-calc(6) rem-calc(12);
        z-index: 1;

        &:is(a, button) {
            &:hover,
            &:focus {
                background: $blue-hover;
                color: $white;
            }
        }

        &--red {
            background: $red;

            &:is(a, button) {
                &:hover,
                &:focus {
                    background: $red-hover;
                }
            }
        }
    }

    &__content {
        @include animate(box-shadow);
        background: $light-gray;
        display: none;
        margin-top: rem-calc(-30);
        max-width: rem-calc(220);
        padding: rem-calc(50) rem-calc(25) rem-calc(20);
        width: calc(80vw);

        .figure,
        .rte {
            &:not(:last-child) {
                margin-bottom: rem-calc(10);
            }
        }
    }

    &--open {
        .ce-floating-action-button__content {
            box-shadow: $box-shadow-overlay;
        }
    }

    @include media-breakpoint-up(lg) {
        bottom: 33%;
        right: rem-calc(20);
    }
}
