/**
 * Content-Teaser
 * -------------------------
 */

.ce-content-teaser {
    @include font-color-variants(dark, true);
    display: block;
    flex: 1 0 auto;
    position: relative;

    &__background {
        @include wallpaper-element();
    }

    &__content {
        @include animate(background-color);
        height: 100%;
        min-height: rem-calc(400);
        padding: rem-calc(20);
        position: relative;
    }

    &__headline {
        @extend %headline-md;
        margin-bottom: rem-calc(10);
    }

    &__read-more {
        bottom: rem-calc(10);
        position: absolute;
        right: rem-calc(10);

        svg {
            fill: currentColor;
        }
    }

    &--fixed-height {
        flex-grow: 0;
    }

    &.ce-content-teaser--light-font {
        &:hover,
        &:focus {
            .ce-content-teaser__content {
                background: transparentize($primary, 0.5);
            }
        }
    }

    &.ce-content-teaser--dark-font {
        &:hover,
        &:focus {
            .ce-content-teaser__content {
                background: transparentize($light-gray, 0.5);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__text--hide-on-mobile {
            visibility: hidden;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            min-height: rem-calc(520);
        }
    }

    @include media-breakpoint-up(lg) {
        &__content {
            min-height: rem-calc(580);
            padding: rem-calc(30);
        }
    }
}
