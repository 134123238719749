/**
 * Global helpers
 * --------------------
 */

.unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

.visibility {
    &--only-mobile {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }

    &--only-desktop {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
}
