/**
 * Big teaser
 * -------------------------
 */

.ce-big-teaser {
    @include font-color-variants(dark, true);
    display: block;

    &__image {
        background-position: center;
        background-size: cover;
    }

    &__content {
        padding: rem-calc(30) rem-calc(20);
        position: relative;

        .headline {
            margin-bottom: rem-calc(10);
            padding-top: rem-calc(22);
            position: relative;

            &:before {
                @include rem-size(50, 2);
                content: '';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .button {
            margin-top: rem-calc(20);
        }
    }

    &--without-image {
        .ce-big-teaser__content {
            .rte {
                max-width: rem-calc(600);
            }
        }

        .ce-big-teaser__read-more {
            bottom: rem-calc(20);
            position: absolute;
            right: rem-calc(20);

            svg {
                @include animate(transform);
                fill: currentColor;
            }
        }

        &:hover,
        &:focus {
            .ce-big-teaser__read-more svg {
                transform: translateX(rem-calc(10));
            }
        }
    }

    &--dark {
        background: $primary;

        .ce-big-teaser__content .headline:before {
            background: map-get($font-colors, 'light');
        }

        @at-root a.ce-big-teaser--dark {
            @include animate(background-color);

            &:hover,
            &:focus {
                background: $primary-hover;
            }
        }
    }

    &--light {
        background: $light-gray;

        .ce-big-teaser__content .headline:before {
            background: map-get($font-colors, 'dark');
        }

        @at-root a.ce-big-teaser--light {
            @include animate(background-color);

            &:hover,
            &:focus {
                background: $light-gray-hover;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &__image {
            padding-top: percentage(9/16);
        }

        &--without-image {
            .ce-big-teaser__content {
                padding-bottom: rem-calc(70);
            }
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: rem-calc(80) rem-calc(60);

            .button {
                margin-top: rem-calc(30);
            }
        }

        &--with-image {
            .ce-big-teaser__wrapper {
                display: flex;
                flex-wrap: wrap;
            }

            .ce-big-teaser__image,
            .ce-big-teaser__content {
                flex: 0 0 50%;
                max-width: 50%;
            }

            &.ce-big-teaser--image-right {
                .ce-big-teaser__image {
                    order: 1;
                }
            }
        }

        &--without-image {
            .ce-big-teaser__wrapper {
                @include make-row();
            }

            .ce-big-teaser__content {
                @include make-col-ready();
                @include make-col(10);
                @include make-col-offset(1);
            }

            .ce-big-teaser__read-more {
                bottom: rem-calc(80);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__content {
            padding: rem-calc(100) rem-calc(80);

            .button {
                margin-top: rem-calc(40);
            }
        }

        &--without-image {
            .ce-big-teaser__read-more {
                bottom: rem-calc(100);
            }
        }
    }
}
