/**
 * Search
 * -------------------------
 */

.search {
    &__form {
        @include animate(height);
        display: flex;
        width: 100%;

        input {
            background: $light-gray;
            border: none;
            flex: 1 1 auto;
            height: rem-calc(36);
            line-height: rem-calc(26);
            order: 1;
            outline: none;
            padding: rem-calc(5) rem-calc(10) rem-calc(5) 0;
            width: 100%;
        }

        button {
            @include rem-size(36);
            background: $light-gray url('#{$image-base-uri}icon-search.svg') no-repeat center;
            border: none;
            display: block;
            flex: 0 0 auto;
            padding: 0;

            &:hover,
            &:focus {
                background-image: url('#{$image-base-uri}icon-search-hover.svg');
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        @include clearfix();

        &__toggle {
            @include rem-size(40);
            background: url('#{$image-base-uri}icon-search.svg') no-repeat center;
            border: none;
            display: block;
            padding: 0;
            position: absolute;
            right: rem-calc(33);
            top: rem-calc(23);

            &:hover,
            &:focus {
                background-image: url('#{$image-base-uri}icon-search-hover.svg');
            }
        }

        &__form {
            float: right;
            height: 0;
            max-width: rem-calc(400);
            overflow: hidden;
        }

        &--open {
            .search__toggle {
                background-image: url('#{$image-base-uri}icon-search-hover.svg');
            }

            .search__form {
                height: rem-calc(63);
            }
        }
    }
}
