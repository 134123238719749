/**
 * Accordion teaser
 * -------------------------
 */

.ce-accordion-teaser {
    background: $light-gray;
    padding: rem-calc(20);

    &__content {
        margin-bottom: rem-calc(15);
    }

    @include media-breakpoint-up(md) {
        padding: rem-calc(40);

        &__content {
            margin-bottom: rem-calc(35);
        }
    }
}
