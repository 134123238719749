/**
 * Grid
 * -------------------------
 */

.ce-grid {
    &__content {
        margin: 0 auto rem-calc(40);
        max-width: rem-calc(785);
        text-align: center;

        .headline {
            margin-bottom: rem-calc(10);
        }
    }

    &__columns {
        margin-bottom: $grid-gutter-width * -1;
    }

    &__column {
        display: flex;
        flex-direction: column;

        &:not(:empty) {
            margin-bottom: $grid-gutter-width;
        }

        > *:not(:last-child) {
            margin-bottom: $grid-gutter-width;
        }
    }

    &__link {
        margin-top: rem-calc(40);
        text-align: center;
    }

    @include media-breakpoint-up(md) {
        &__content {
            margin-bottom: rem-calc(60);
        }

        &__columns {
            @include make-row();
            align-items: stretch;
        }

        &__column {
            @include make-col-ready();

            &--small {
                @include make-col(6);
            }
        }

        &__link {
            margin-top: rem-calc(60);
        }
    }

    @include media-breakpoint-up(lg) {
        &__content {
            margin-bottom: rem-calc(80);
        }

        &__column {
            &--large {
                @include make-col(6);
            }

            &--medium {
                @include make-col(4);
            }

            &--small {
                @include make-col(3);
            }
        }

        &__link {
            margin-top: rem-calc(80);
        }
    }
}
