/**
 * Page-Intro (alternative version)
 * -------------------------
 */

.ce-page-intro-alternative {
    @include clearfix();

    &__box {
        background: $light-gray;
    }

    &__content {
        padding: rem-calc(40) rem-calc(20);

        .headline {
            margin-bottom: rem-calc(10);
        }

        .rte {
            max-width: rem-calc(600);
        }

        .tags {
            margin-top: rem-calc(20);
        }
    }

    &__image {
        img {
            @include img-fluid();
        }
    }

    &__map {
        display: none;
    }

    .share {
        margin-top: rem-calc(10);
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        &__wrapper-inner {
            @include make-row();
            align-items: flex-end;
            min-height: rem-calc(400);
        }

        &__content {
            @include make-col-ready();
            @include make-col(10);
            @include make-col-offset(1);
            padding-bottom: rem-calc(80);
            padding-top: rem-calc(80);

            .tags {
                margin-top: rem-calc(30);
            }
        }

        &__map {
            height: rem-calc(450);
            display: block;
        }

        &--with-image {
            .ce-page-intro-alternative__content {
                @include make-col(6);
            }

            .ce-page-intro-alternative__image {
                @include make-col-ready();
                @include make-col(4);
                margin: rem-calc(80) 0;
                text-align: right;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__wrapper-inner {
            min-height: rem-calc(450);
        }

        &__content {
            padding-bottom: rem-calc(100);
            padding-top: rem-calc(100);

            .tags {
                margin-top: rem-calc(40);
            }
        }

        &__map {
            height: rem-calc(540);
        }

        &--with-image {
            .ce-page-intro-alternative__image {
                margin: rem-calc(150) 0 rem-calc(-50);
            }

            .share {
                width: calc(#{percentage(7/12)} + #{rem-calc(7)});

                &__toggle-button {
                    float: right;
                }
            }
        }
    }
}
