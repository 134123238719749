/**
 * Filters
 * -------------------------
 */

.filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem-calc(-10) rem-calc(20);

    &__filter {
        margin: 0 rem-calc(10) rem-calc(20);
        position: relative;
        width: 100%;

        &-label {
            @include copy-text();
            background: $light-gray;
            border: none;
            border-bottom: rem-calc(1) solid $input-border-color;
            height: 60px;
            padding: 16px 15px 17px 15px;
            position: relative;
            text-align: left;
            width: 100%;

            &:before, &:after {
                border: rem-calc(4) dashed;
                border-color: $primary transparent;
                content: '';
                height: 0;
                pointer-events: none;
                position: absolute;
                right: 15px;
                top: 25px;
                width: 0;
            }

            &:before {
                border-bottom-style: solid;
                border-top: none;
            }

            &:after {
                border-bottom: none;
                margin-top: rem-calc(7);
                border-top-style: solid;
            }
        }

        &-overlay {
            @include animate(transform);
            background: $light-gray;
            bottom: 0;
            left: 0;
            padding: rem-calc(10) rem-calc(15) rem-calc(15);
            position: fixed;
            right: 0;
            z-index: 1000;

            &[hidden] {
                display: block !important;
                transform: translateY(100%);
            }
        }

        &-header,
        &-toolbar {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &-header {
            font-weight: bold;
        }

        &-options {
            margin: rem-calc(15) 0;
        }

        &-option {
            margin-bottom: rem-calc(15);

            input {
                @extend .form__checkbox;
            }
        }

        @include media-breakpoint-up(sm) {
            max-width: rem-calc(300);

            &-overlay {
                @include animate(opacity);
                bottom: auto;
                opacity: 1;
                position: absolute;
                top: 100%;

                &[hidden] {
                    opacity: 0;
                    pointer-events: none;
                    transform: none;
                }
            }

            &-header {
                display: none;
            }
        }
    }

    &__selected-values {
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        margin: 0 rem-calc(10);
        width: 100%;

        .link {
            margin-bottom: rem-calc(20);
            order: 1;
        }
    }

    &__selected-value {
        background: $light-gray url('#{$image-base-uri}icon-close.svg') no-repeat right;
        border: none;
        line-height: rem-calc(40);
        margin: 0 rem-calc(20) rem-calc(20) 0;
        padding: 0 rem-calc(40) 0 rem-calc(15);
    }
}
