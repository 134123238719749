/**
 * Layout
 * -------------------------
 */

.page-wrapper {
    background: $white;
    margin: 0 auto;
    max-width: rem-calc(1680);

    @include media-breakpoint-down(md) {
        padding-top: rem-calc(50);
    }
}

.container {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
    }

    @include media-breakpoint-up(lg) {
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
    }
}

@each $direction in bottom top {
    .spacing-#{$direction} {
        margin-#{$direction}: rem-calc(60);

        &--small {
            margin-#{$direction}: rem-calc(20);
        }

        &--medium {
            margin-#{$direction}: rem-calc(40);
        }

        @include media-breakpoint-up(md) {
            margin-#{$direction}: rem-calc(80);

            &--small {
                margin-#{$direction}: rem-calc(30);
            }

            &--medium {
                margin-#{$direction}: rem-calc(60);
            }
        }

        @include media-breakpoint-up(lg) {
            margin-#{$direction}: rem-calc(120);

            &--small {
                margin-#{$direction}: rem-calc(40);
            }

            &--medium {
                margin-#{$direction}: rem-calc(80);
            }
        }
    }
}
