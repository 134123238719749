/**
 * Highlight teaser
 * -------------------------
 */

.ce-highlight-teaser-container {
    &__content {
        margin: 0 auto rem-calc(40);
        max-width: rem-calc(785);
        text-align: center;

        .headline {
            margin-bottom: rem-calc(10);
        }
    }

    &__teasers {
        margin-bottom: rem-calc(-10);

        .headline {
            margin-bottom: rem-calc(30);
            margin-top: rem-calc(10);
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            margin-bottom: rem-calc(60);
        }

        &__teasers {
            @include make-row();
            margin-bottom: rem-calc(-20);
        }
    }

    @include media-breakpoint-up(lg) {
        &--narrow {
            @include make-row();

            .ce-highlight-teaser-container__inner {
                @include make-col-ready();
                @include make-col(10);
                @include make-col-offset(1);
            }
        }

        &__content {
            margin-bottom: rem-calc(80);
        }

        &__teasers {
            margin-bottom: rem-calc(-40);
        }
    }
}

.ce-highlight-teaser {
    margin-bottom: rem-calc(10);

    &__inner {
        @include background-color-variants(white);
        display: block;
        height: 100%;
        position: relative;

        &:hover,
        &:focus {
            .ce-highlight-teaser__overlay {
                opacity: 0.9;
            }
        }
    }

    &__background {
        @include wallpaper-element();
    }

    &__overlay {
        @include animate(opacity);
        @include background-color-variants(white);
        @include stretch-to-parent();
        opacity: 0.5;
    }

    &__content {
        @include animate(background-color);
        @include font-color-variants(dark);
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: rem-calc(40) rem-calc(20);
        position: relative;
        text-align: center;

        .rte {
            margin: 0 auto;
            max-width: 100%;
            width: rem-calc(600);
        }

        .link {
            margin-top: rem-calc(20);
        }
    }

    &__headline {
        margin: 0 auto rem-calc(10);
        max-width: 100%;
        width: rem-calc(900);
    }

    &--large {
        .ce-highlight-teaser__headline {
            @extend %headline-lg;
        }
    }

    &:not(&--large) {
        .ce-highlight-teaser__headline {
            @extend %headline-md;
        }
    }

    @include media-breakpoint-up(md) {
        @include make-col-ready();
        margin-bottom: $grid-gutter-width;

        &--medium {
            @include make-col(1, 2);
        }

        &--small {
            @include make-col(4);
        }

        &__content {
            min-height: rem-calc(440);
            padding: rem-calc(80) rem-calc(30);

            .link {
                margin-top: rem-calc(20);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__content {
            padding: rem-calc(100) rem-calc(40);

            .link {
                margin-top: rem-calc(40);
            }
        }
    }
}
