/**
 * Search results
 * -------------------------
 */

.ce-search-results {
    &__counter {
        @include copy-text();
        margin-bottom: rem-calc(30);
    }

    &__result {
        @include animate(background-position);
        background: url('#{$image-base-uri}icon-arrow-big-right-dark.svg') no-repeat right bottom rem-calc(20);
        border-top: rem-calc(2) solid $divider;
        padding: rem-calc(20) 0;
        position: relative;
        overflow: hidden;

        &:hover {
            background-position: right rem-calc(-10) bottom rem-calc(20);
        }

        &:last-child {
            border-bottom: rem-calc(2) solid $divider;
        }

        .headline {
            margin-bottom: rem-calc(10);

            a:after {
                @include stretch-to-parent();
                content: '';
            }
        }

        .rte {
            padding-right: rem-calc(60);
        }
    }

    @include media-breakpoint-up(md) {
        @include make-row();

        &__wrapper {
            @include make-col-ready();
            @include make-col(10);
            @include make-col-offset(1);
        }
    }

    @include media-breakpoint-up(lg) {
        &__wrapper {
            @include make-col(8);
            @include make-col-offset(2);
        }
    }
}
