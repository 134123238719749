/**
 * Share menu and social links
 * -------------------------
 */

.share {
    @include rem-size(40);
    position: relative;
    filter: url('#goo-filter');

    &__toggle-button {
        @include animate(color transform);
        @include rem-size(40);
        background: none;
        border: none;
        color: $primary;
        fill: currentColor;
        float: right;
        padding: 0;
        position: relative;
        transform: translate3d(0, 0, 0);
        z-index: 1;

        &:hover,
        &:focus {
            color: $primary-hover;
            transform: scale(1.2) translate3d(0, 0, 0);
        }
    }

    &__toggle-icon {
        background: $white;
        border-radius: 50%;
        display: block;

        &--open {
            display: none;
        }
    }

    &__links {
        @include clearfix();
        @extend .unstyled-list;

        li {
            @include animate(transform);
            @include rem-size(40);
            margin: 0 rem-calc(2);
            padding: rem-calc(8);
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__link {
        @include animate(background-color transform);
        @include rem-size(24);
        background: $primary no-repeat center;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        padding: rem-calc(1);
        transform: translate3d(0, 0, 0);

        svg {
            display: block;
            fill: currentColor;
        }

        &:hover,
        &:focus {
            background-color: $primary-hover;
            color: $white;
            transform: scale(1.2) translate3d(0, 0, 0);
        }
    }

    &--open {
        .share__toggle-icon {
            &--open {
                display: block;
            }

            &--closed {
                display: none;
            }
        }

        .share__links li {
            transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    transition-duration: 500ms + $i * 150;
                    transform: translate3d(#{rem-calc((6 + 44 * $i) * -1)}, 0, 0);
                }
            }
        }
    }
}

.social-links {
    @extend .unstyled-list;
    @include clearfix();

    > li {
        float: left;

        &:not(:last-child) {
            margin-right: rem-calc(10);
        }
    }

    &__link {
        svg {
            @include rem-size(30);
            display: block;
            fill: currentColor;
        }
    }
}
