/**
 * Map
 * -------------------------
 */

.ce-map {
    @include clearfix();

    &__map {
        height: rem-calc(240);
    }

    .share {
        margin-top: rem-calc(10);
        width: 100%;
    }

    @include media-breakpoint-up(sm) {
        &__map {
            height: rem-calc(280);
        }
    }

    @include media-breakpoint-up(md) {
        &__map {
            height: rem-calc(450);
        }
    }

    @include media-breakpoint-up(lg) {
        &__map {
            height: rem-calc(540);
        }
    }
}
