/**
 * Record List Teaser
 * -------------------------
 */

.ce-record-list-teaser {
    flex: 0 0 auto;

    &__content {
        padding-bottom: rem-calc(20);
        position: relative;

        .headline {
            margin-bottom: rem-calc(5);
        }

        .rte {
            margin-bottom: rem-calc(5);
        }
    }

    &__link {
        padding-top: rem-calc(20);
    }
}
