/**
 * Tags
 * -------------------------
 */

.tags {
    margin-bottom: rem-calc(-10);

    &__link {
        @include animate(background-color);
        @extend %tiny;
        background: $primary;
        color: $white !important;
        display: inline-block;
        margin-bottom: rem-calc(10);
        padding: rem-calc(5) rem-calc(10) rem-calc(4);
        white-space: nowrap;

        &:not(:last-child) {
            margin-right: rem-calc(20);
        }
    }

    a.tags__link {
        &:hover,
        &:focus {
            background: $primary-hover;
        }
    }
}
