/**
 * Job offers
 * -------------------------
 */

.ce-job-offers {
    &__filter {
        margin-bottom: rem-calc(40);
    }

    &__list {
        @include list-unstyled();
        @include copy-text();

        li:not(:last-child) {
            margin-bottom: rem-calc(20);
        }

        span {
            display: block;
            padding-left: rem-calc(35);
        }
    }

    @include media-breakpoint-up(md) {
        @include make-row();

        &__wrapper {
            @include make-col-ready();
            @include make-col(10);
            @include make-col-offset(1);
        }

        &__filter {
            margin-bottom: rem-calc(80);
        }

        &__list {
            li {
                display: flex;
                justify-content: space-between;
            }

            span {
                padding-left: rem-calc(20);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__wrapper {
            @include make-col(8);
            @include make-col-offset(2);
        }
    }
}
