/**
 * Search form
 * -------------------------
 */

.ce-search-form {
    background: $light-gray;

    &__wrapper {
        padding: rem-calc(40) rem-calc(15) rem-calc(20);
    }

    &__form {
        .headline {
            margin-bottom: rem-calc(10);
        }

        fieldset {
            border-bottom: rem-calc(1) solid $input-border-color;
            display: flex;
            max-width: rem-calc(565);
            width: 100%;
        }

        input {
            background: $white;
            border: none;
            flex: 1 1 auto;
            font-size: rem-calc(17);
            height: rem-calc(60);
            line-height: rem-calc(26);
            order: 1;
            outline: none;
            padding: rem-calc(17) rem-calc(10) rem-calc(17) 0;
            width: 100%;
        }

        button {
            @include rem-size(60);
            background: $white url('#{$image-base-uri}icon-search.svg') no-repeat center;
            background-size: contain;
            border: none;
            display: block;
            flex: 0 0 auto;
            padding: 0;

            &:hover,
            &:focus {
                background-image: url('#{$image-base-uri}icon-search-hover.svg');
            }
        }
    }

    &--no-text {
        background: none;

        .ce-search-form__wrapper {
            padding: 0;
        }

        .ce-search-form__form {
            fieldset {
                margin: 0 auto;
            }

            fieldset, button, input {
                background-color: $light-gray;
            }
        }

        .error-messages {
            margin: rem-calc(30) auto 0;
            max-width: rem-calc(565);
        }
    }

    @include media-breakpoint-up(md) {
        &__wrapper {
            padding: rem-calc(100) rem-calc(60) rem-calc(50);
        }

        &--no-text {
            .ce-search-form__wrapper {
                padding: 0 rem-calc(60);
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &__wrapper {
            padding: rem-calc(200) rem-calc(120) rem-calc(100);
        }

        &--no-text {
            .ce-search-form__wrapper {
                padding: 0 rem-calc(120);
            }
        }
    }
}
