/**
 * Header
 * -------------------------
 */

.header {
    padding: 0 rem-calc(15);
    position: relative;

    &__inner {
        @include clearfix();
    }

    &__logo {
        display: block;
        float: left;
        margin: rem-calc(12) 0 rem-calc(7);

        @if $theme == 'mankiewicz' {
            @include rem-size(186, 31);
            background: url('#{$image-base-uri}logo-mankiewicz.svg') no-repeat center/contain;
        } @else if $theme == 'alexseal' {
            @include rem-size(136, 31);
            background: url('#{$image-base-uri}logo-alexseal.svg') no-repeat center/contain;
        } @else if $theme == 'bladerep' {
            @include rem-size(173, 31);
            background: url('#{$image-base-uri}logo-bladerep.svg') no-repeat center/contain;
        } @else if $theme == 'nextel' {
            @include rem-size(138, 31);
            background: url('#{$image-base-uri}logo-nextel.svg') no-repeat center/contain;
        } @else if $theme == 'ruedt' {
            @include rem-size(89, 31);
            background: url('#{$image-base-uri}logo-ruedt.svg') no-repeat center/contain;
        } @else if $theme == 'schuppenpanzer' {
            @include rem-size(247, 31);
            background: url('#{$image-base-uri}logo-schuppenpanzer.svg') no-repeat center/contain;
        }
    }

    @include media-breakpoint-down(md) {
        background: $white;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;

        &__inner {
            position: relative;
        }
    }

    @include media-breakpoint-up(md) {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
    }

    @include media-breakpoint-up(lg) {
        border-top: rem-calc(40) solid $light-gray;
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);

        &__logo {
            margin-bottom: rem-calc(15);
            margin-top: rem-calc(23);

            @if $theme == 'mankiewicz' {
                @include rem-size(252, 42);
            } @else if $theme == 'alexseal' {
                @include rem-size(184, 42);
            } @else if $theme == 'bladerep' {
                @include rem-size(235, 42);
            } @else if $theme == 'nextel' {
                @include rem-size(187, 42);
            } @else if $theme == 'ruedt' {
                @include rem-size(121, 42);
            } @else if $theme == 'schuppenpanzer' {
              @include rem-size(377, 42);
            }
        }
    }
}
