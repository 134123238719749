/**
 * Forms
 * -------------------------
 */

.form {
    &__label {
        @include copy-text();
        color: transparentize($black, 0.7);
        cursor: text;
        display: block;
        margin: 0 rem-calc(15) rem-calc(-26) rem-calc(15);
        overflow: hidden;
        pointer-events: none;
        text-overflow: ellipsis;
        transform: translateY(#{rem-calc(17)});
        transform-origin: 0 0;
        transition: all 300ms cubic-bezier(.23,1,.32,1);
        white-space: nowrap;

        &--focused {
            color: $primary;
            transform: translateY(10px) scale(.7);
        }

        &--static {
            color: $primary;
            font-size: rem-calc(13);
            margin: 0 0 rem-calc(5);
            pointer-events: initial;
            transform: none;
        }
    }

    &__field {
        background: $light-gray;
        border: none;
        border-bottom: rem-calc(1) solid $input-border-color;
        display: block;
        height: 60px;
        outline: 0;
        padding: 26px 15px 7px 15px;
        width: 100%;
        transition: all 300ms cubic-bezier(.23,1,.32,1);

        &:focus {
            border-bottom-color: $primary;
        }

        @at-root {
            select.form__field {
                -webkit-appearance: none;
                -webkit-border-radius: 0;

                &::-ms-expand {
                    display: none;
                }
            }

            textarea.form__field {
                height: rem-calc(240);
                resize: none;
            }
        }
    }

    &__checkbox {
        position: absolute;
        opacity: 0;

        + label {
            @include copy-text();
            position: relative;
            cursor: pointer;
            margin: 0;
            padding: 0 0 0 rem-calc(30);
            width: 100%;

            &:before {
                content: '';
                margin-top: 3px;
                margin-right: 10px;
                display: block;
                position: absolute;
                left: 0px;
                width: 18px;
                height: 18px;
                background: #F2F2F5;
                border: 1px solid #777779;
            }
        }

        &:hover {
            + label:before {
                background: #E4E4E8;
            }
        }

        &:focus {
            + label:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }
        }

        &:checked {
            + label:after {
                content: '';
                position: absolute;
                left: 4px;
                top: 7px;
                background: #0C35AA;
                width: 10px;
                height: 10px;
            }
        }
    }

    &__radio {
        @extend .form__checkbox;

        + label:before {
            border-radius: rem-calc(9);
        }

        &:checked {
            + label:after {
                border-radius: rem-calc(5);
            }
        }
    }

    &__field-wrapper {
        position: relative;

        &:not(:first-child) {
            margin-top: rem-calc(20);
        }

        &--textarea:not(:first-child) {
            margin-top: rem-calc(40);
        }

        &--select {
            &:before, &:after {
                border: rem-calc(4) dashed;
                border-color: $primary transparent;
                content: '';
                height: 0;
                pointer-events: none;
                position: absolute;
                right: 15px;
                top: 25px;
                width: 0;
            }

            &:before {
                border-bottom-style: solid;
                border-top: none;
            }

            &:after {
                border-bottom: none;
                margin-top: rem-calc(7);
                border-top-style: solid;
            }
        }

        &--small {
            max-width: rem-calc(180);
        }
    }

    &__button-wrapper {
        text-align: right;
    }

    &__submit-notice {
        margin-top: rem-calc(20);
    }

    &__fieldset + &__fieldset {
        margin-top: rem-calc(40);
    }

    &__legend {
        @extend %headline-sm;
        margin: 0;
    }

    &__conditional-fields {
        margin-top: rem-calc(20);
    }

    &__row {
        &:first-child {
            margin-top: rem-calc(-20);
        }

        @include media-breakpoint-up(md) {
            display: flex;
            margin-top: 0;
            margin-right: rem-calc(-10);
            margin-left: rem-calc(-10);

            &:first-child {
                margin-top: 0;
            }

            &:not(:first-child) {
                margin-top: rem-calc(20);
            }
        }

        @include media-breakpoint-up(lg) {
            &--large-gutter {
                margin-right: rem-calc(-48);
                margin-left: rem-calc(-48);
            }
        }
    }

    &__column {
        margin-top: rem-calc(20);

        @include media-breakpoint-up(md) {
            flex: 1 1 100%;
            margin-top: 0;
            max-width: 100%;
            padding-right: rem-calc(10);
            padding-left: rem-calc(10);

            &--small {
                flex-grow: 0.5;
            }

            &--large {
                flex-shrink: 0.5;
            }
        }

        @include media-breakpoint-up(lg) {
            @at-root .form__row--large-gutter & {
                padding-right: rem-calc(48);
                padding-left: rem-calc(48);
            }
        }
    }

    &__errors {
        @extend .powermail-errors-list;
        display: block;
    }
}

.button {
    background: transparentize($white, 0.7);
    border: rem-calc(2) solid $primary;
    color: $primary;
    display: inline-block;
    font-size: rem-calc(14);
    font-weight: bold;
    line-height: rem-calc(36);
    padding: 0 rem-calc(28);
    text-align: center;
    white-space: nowrap;

    &[disabled] {
        border-color: $primary;
        color: $primary;
        cursor: not-allowed;
        opacity: 0.7;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
        background: $primary;
        border-color: $primary;
        color: $white;
        text-decoration: none;
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--primary {
        background: $primary;
        color: $white;

        &[disabled] {
            color: $white;
        }
    }

    &--light-font {
        border-color: $white;
        color: $white;

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            border-color: $white;
            color: $white;
        }
    }

    &--black-font {
        border-color: $black;
        color: $black;

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            background: $black;
            border-color: $black;
        }
    }

    &--text {
        background: none;
        border: none;

        @include hover-focus() {
            background: $light-gray;
            color: $primary;
        }
    }
}

.quantity-selector {
    border: rem-calc(2) solid $primary;
    display: inline-flex;

    button,
    input {
        display: block;
        border: none;
        width: rem-calc(36);
        font-size: rem-calc(14);
        line-height: rem-calc(20);
        font-weight: bold;
        padding: rem-calc(8) 0;
        text-align: center;
    }

    button {
        color: $white;
        background: $primary;

        &:hover,
        &:focus {
            background: $primary-hover;
        }
    }

    input {
        -moz-appearance:textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.parsley-errors-list,
.powermail-errors-list {
    @include tiny-text();
    background: $error;
    color: $white;
    display: none;
    font-weight: bold;
    list-style: none;
    margin: rem-calc(-1) 0 0;
    padding: rem-calc(12) rem-calc(15) rem-calc(11);
    position: relative;
    z-index: 1;

    &.filled {
        display: block;
    }

    &:after {
        border: solid transparent;
        border-bottom-color: $error;
        border-width: rem-calc(5);
        bottom: 100%;
        content: ' ';
        display: block;
        height: 0;
        left: rem-calc(20);
        pointer-events: none;
        position: absolute;
        margin-left: rem-calc(-5);
        width: 0;
    }

    li {
        padding: 0;
    }
}

.powermail_field_error_container:not(:empty) {
    margin-top: rem-calc(25);
}
